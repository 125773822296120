table{
  width: 100%;
  margin: 3rem 0;
  border: 1px solid #dfe2e5;
}

tr{
  border: 1px solid #dfe2e5;
}

td, th{
  padding: .8rem;
}

.cite code{
  white-space:nowrap;
}
