@font-face {
  font-family: Antinoou;
  src: url('fonts/Antinoou.ttf');
}
.coptic{
  font-family: Antinoou !important;
  font-size: 1.1rem;
}
.arabic{
  direction: rtl;
}
html {
  position: relative;
  min-height: 100%;
  height: 100%;
}
body {
  height: 100%;
}
.maxHeight{
  height: 100%;
}

main{
  overflow: hidden;
  padding-top: 56px;
  min-height: 600px;
}

#sidebar{
  margin-top: 60px;
}

.sidebar-header{
  margin-bottom: 20px;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,.7);
}

nav.bg-dark,
.sidebar-header,
.sidebar-tabs>li.active,
.sidebar-tabs>ul>li.active{
  background: #273c75 !important;
  background: #c23616 !important;
  background: #b33939 !important;
}
footer a{
  color: #ffab00;
}
footer a:hover{
  color: #fff;
}
footer .text-secondary a{
  color: #fff;
}

@media only screen and (min-width: 768px){
  .double-column{
    column-count: 2;
    column-gap: '5rem'
  }
  .double-column blockquote{
    margin-left: 1rem;
    padding-left: 1rem;
    margin-right: 3rem;
    border-left: 2px solid #ebebeb;
  }
}
